.section2-container {
 padding: 80px 100px 80px 100px;
background-color: #ffff;
border-bottom-left-radius: 60px;
border-bottom-right-radius: 60px;
 height: 120vh;
}  
.section2-div1{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: 100px;
} 
.section2-div-image{
  width: 60%;
}
.section2-div2 {
  display: flex;
  padding: 80px 0px 20px 0px;
}
.section2-div2-l{
  width: 50%;
  padding-top: 100px;
}
.section2-div2-l-div1 {
  width: 60%;
}

.section2-div2-l-span1{
  font-family: 'GeneralSans-Bold';
  font-size: 75px;
  line-height: 80px;
}
.section2-div2-l-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.section2-div2-l-div-p{
  font-size: 18px;
  font-family: 'GeneralSans-Medium';
  line-height: 24px;
  color:#AAAAAA

}
.section-2-div2-l-div-details{
  display: flex;
  margin-top: 30px;
  margin-left: 50px;
}
.section-2-div2-l-div-details-1{
  width: 70%;
}

.section2-div2-r{
  width: 50%;
}
.section2-div2-r-video {
  width: 100%;
  clip-path: inset(1px 1px);
}
/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
@media (min-width:320px) {
  .section2-container {
    margin-top: 0px;
    padding: 0px 20px 0px 20px;
   background-color: #ffff;
   border-bottom-left-radius: 40px;
   border-bottom-right-radius: 40px;
    height: auto;
    overflow: hidden;
   }  
   .section2-div2 {
    display: block;
    padding: 0px 0px 30px 0px;
  }
  .section2-div2-l{
    width: 100%;
    padding-top: 30px;
  }
  .section2-div2-l-span1{
    font-family: 'GeneralSans-Bold';
    font-size: 40px;
    line-height: 50px;
  }
  .section2-div2-l-div {
    display: block;
    width: 100%;

  }
  .section2-div2-l-div1 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .section-2-div2-l-div-details{
    display: flex;
    margin-top: 10px;
    margin-left: 0px;
  }
  .section-2-div2-l-div-details-1{
    width: 100%;
  }
  .cards{
    width: 60%;
  }
  .section2-div2-r{
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
  }
  .section2-div-image{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .section2-div2-r-video {
    width: 100%;
    clip-path: inset(1px 1px);
    height: 100%
  }
} 
/* big landscape tablets, laptops, and desktops */ 
@media (min-width:1025px) {
  .section2-container {
    margin-top:0;
    padding: 80px 100px 0px 100px;
   background-color: #ffff;
   border-bottom-left-radius: 40px;
   border-bottom-right-radius: 40px;
    height: auto;
    overflow: hidden;
   }  
   .section2-div2 {
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
  }
  .section2-div2-l{
    width: 50%;
    padding-top: 50px;
  }
  .section2-div2-l-span1{
    font-family: 'GeneralSans-Bold';
    font-size: 75px;
    line-height: 80px;
  }
  .section2-div2-l-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .section2-div2-l-div1 {
    width: 80%;
    display: block;
  }
  .section-2-div2-l-div-details{
    display: flex;
    margin-top: 0px;
    margin-left: 50px;
  }
  .section-2-div2-l-div-details-1{
    width: 100%;
  }
  .cards{
    width: 100%;
  }
  .section2-div2-r{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .section2-div-image{
    width: 60%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .section2-div2-r-video {
    width: 92%;
    clip-path: inset(2px 1px);
    height: 92%;
  }
}
/* hi-res laptops and desktops */
@media (min-width:1281px) {
  .section2-container {
    margin-top:0;
    padding: 80px 100px 0px 100px;
   background-color: #ffff;
   border-bottom-left-radius: 40px;
   border-bottom-right-radius: 40px;
    height: auto;
    overflow: hidden;
   }
   .section2-div2 {
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
  }  
  .section2-div2-l{
    width: 50%;
    padding-top: 50px;
  }
  .section2-div2-l-span1{
    font-family: 'GeneralSans-Bold';
    font-size: 75px;
    line-height: 80px;
  }
  .section2-div2-l-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .section2-div2-l-div1 {
    width: 80%;
    display: block;
  }
  .section-2-div2-l-div-details{
    display: flex;
    margin-top: 0px;
    margin-left: 50px;
  }
  .section-2-div2-l-div-details-1{
    width: 70%;
  }
  .cards{
    width: 100%;
  }
  .section2-div2-r{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .section2-div-image{
    width: 60%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .section2-div2-r-video {
    width: 92%;
    clip-path: inset(2px 1px);
    height: 92%;
  }
  
}