.header-video-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(
    to left,
    #4bb9ab,
    #6ac7bd,
    #86d5ce,
    #a1e4df,
    #bcf2ef
  );
  display: flex;
  justify-content: center;
}
.video-element {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.dynamicDiv {
  width: 100vw;
  height: 100%;
  display: flex;
  background-image: linear-gradient(
    to left,
    #4bb9ab,
    #6ac7bd,
    #86d5ce,
    #a1e4df,
    #bcf2ef
  );
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
}


.dynamicImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; /* normally it's the default value */
}
@media (min-width: 320px) {
  .header-video-container {
    width: 100%;
    height: calc( 100vh );

  }
  .dynamicDiv {
    width: 100vw;
    height: 100%;
    display: flex;
    background-image: linear-gradient(
      to left,
      #4bb9ab,
      #6ac7bd,
      #86d5ce,
      #a1e4df,
      #bcf2ef
    );
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
  }

  
}
@media (min-width: 1025px) {
  .header-video-container {
    /* width: 100%;
    height: 100vh; */
    overflow: hidden;
  }
  .dynamicDiv {
    width: 100vw;
    height: 100%;
    display: flex;
    background-image: linear-gradient(
      to left,
      #4bb9ab,
      #6ac7bd,
      #86d5ce,
      #a1e4df,
      #bcf2ef
    );
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
  }
}
@media (min-width: 1281px) {
  .header-video-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .dynamicDiv {
    width: 100vw;
    height: 100%;
    display: flex;
    background-image: linear-gradient(
      to left,
      #4bb9ab,
      #6ac7bd,
      #86d5ce,
      #a1e4df,
      #bcf2ef
    );
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
  }
}
