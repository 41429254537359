body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'GeneralSans-Bold';
  src: local('GeneralSans-Bold'), url(./assets/fonts/GeneralSans-Bold.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-BoldItalic';
  src: local('GeneralSans-BoldItalic'), url(./assets/fonts/GeneralSans-BoldItalic.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-Extralight';
  src: local('GeneralSans-Extralight'), url(./assets/fonts/GeneralSans-Extralight.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-Italic';
  src: local('GeneralSans-Italic'), url(./assets/fonts/GeneralSans-Italic.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-Bold';
  src: local('GeneralSans-Bold'), url(./assets/fonts/GeneralSans-Bold.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-Light';
  src: local('GeneralSans-Light'), url(./assets/fonts/GeneralSans-Light.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-LightItalic';
  src: local('GeneralSans-LightItalic'), url(./assets/fonts/GeneralSans-LightItalic.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-Medium';
  src: local('GeneralSans-Medium'), url(./assets/fonts/GeneralSans-Medium.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-MediumItalic';
  src: local('GeneralSans-MediumItalic'), url(./assets/fonts/GeneralSans-MediumItalic.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-Regular';
  src: local('GeneralSans-Regular'), url(./assets/fonts/GeneralSans-Regular.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-Semibold';
  src: local('GeneralSans-Semibold'), url(./assets/fonts/GeneralSans-Semibold.otf) format('woff');
}
@font-face {
  font-family: 'GeneralSans-SemiboldItalic';
  src: local('GeneralSans-SemiboldItalic'), url(./assets/fonts/GeneralSans-SemiboldItalic.otf) format('woff');
}
button {
  border: none;
  outline: none;
  background: white;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #555;
}


.img-height{
  height: 150px;
  width: 100%;
 
}
.video-tag{
  width: 100%;
  height: 100%;
}


/* .stop-scrolling {
  height: 100%;
  overflow: hidden;
} */