.container{
top: 0px;
left: 0px;
/* UI Properties */
background: transparent url('../../assets/images/Rectangle 1879.png') 0% 0% no-repeat padding-box;
opacity: 1;
display: flex;
justify-content: center;
/* position: sticky; */
}
.logo{
    width: 230px;
    height: 100%;
    margin: 20px 0px 20px 0px;
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
@media (min-width:320px) {

} 
/* big landscape tablets, laptops, and desktops */ 
@media (min-width:1025px) {
   
}
/* hi-res laptops and desktops */
@media (min-width:1281px) {
    
}