.section3-container {
  position: relative;
 padding: 80px 40px 80px 40px;
 display: flex;
} 

.section3-div1 {
  width: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section3-div2 {
  width: 34%;
}
.section3-div3 {
  width: 70%;
  /* padding-top: 30px;
  padding-bottom: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section3-div3-p {
  font-family: 'GeneralSans-Bold';
  font-size: 42px;
  margin: 0;
  color: #ffffff;
  opacity: 0.3;
}





/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
@media (min-width:320px) {
  .section3-container {
    position: relative;
   padding: 30px 20px 40px 20px;
   display: flex;
   flex-direction: column;
  } 
  
  .section3-div1 {
    width: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section3-div2 {
    width: 96%;
  }
  .section3-div3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
  }
  .section3-div3-p {
    font-family: 'GeneralSans-Bold';
    font-size: 30px;
    margin: 0;
    color: #ffffff;
    opacity: 0.3;
  }
} 
/* big landscape tablets, laptops, and desktops */ 
@media (min-width:1025px) {
  .section3-container {
    position: relative;
   padding: 80px 40px 80px 40px;
   display: flex;
   flex-direction: row;
  } 
  
  .section3-div1 {
    width: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section3-div2 {
    width: 34%;
  }
  .section3-div3 {
    width: 70%;
    /* padding-top: 30px;
    padding-bottom: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
  .section3-div3-p {
    font-family: 'GeneralSans-Bold';
    font-size: 42px;
    margin: 0;
    color: #ffffff;
    opacity: 0.3;
  }
}
/* hi-res laptops and desktops */
@media (min-width:1281px) {
  .section3-container {
    position: relative;
   padding: 80px 40px 80px 40px;
   display: flex;
   flex-direction: row;
  } 
  
  .section3-div1 {
    width: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section3-div2 {
    width: 34%;
  }
  .section3-div3 {
    width: 70%;
    /* padding-top: 30px;
    padding-bottom: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
  .section3-div3-p {
    font-family: 'GeneralSans-Bold';
    font-size: 42px;
    margin: 0;
    color: #ffffff;
    opacity: 0.3;
  }
}