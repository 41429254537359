.section7-container {
  position: relative;
 padding: 40px 40px 40px 40px;
 display: flex;
} 





/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
@media (min-width:320px) {

} 
/* big landscape tablets, laptops, and desktops */ 
@media (min-width:1025px) {
   
}
/* hi-res laptops and desktops */
@media (min-width:1281px) {
    
}