.section1-container {
  background-color: #ffffff !important;
  padding: 80px 100px 80px 100px;
  height: 78vh;
  /* margin-top: -50px; */
}
.containertwo {
  padding: 0 100px 0 100px;
}
.section1-part1 {
  padding: 40px 80px 40px 80px;
  display: flex;
  width: 100%;
}
.section1-part1-div1 {
  width: 50%;
  max-height: 100px;
}
.section1-part1-div2 {
  width: 50%;
  max-height: 100px;
  display: flex;
  justify-content: flex-end;
}
.section1-part1-div2-div {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.section1-part1-span1 {
  font-family: "GeneralSans-Bold";
  font-size: 42px;
}
.section1-part1-span2 {
  font-family: "Arial, Helvetica, sans-serif";
  font-size: 20px;
  color: #aaaaaa;
}
.slider {
  margin: 0px 50px 0px 5px;
  width: 500px !important;
}
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
  width: 100%;
}
.embla__slide1 {
  flex: 0 0 50%;
  overflow: hidden;
  margin: 0px 20px 0px 20px;
}
.embla__slide2 {
  flex: 0 0 25%;
  overflow: hidden;
  margin: 0px 20px 0px 0px;
}
.embla__slide3 {
  flex: 0 0 25%;
  overflow: hidden;
  margin: 0px 20px 0px 0px;
}
img {
  width: 100%;
  /* object-fit: contain; */
}

.slick-slide {
  /* padding: 5px; */
  /* margin: 10px; */
}
.react-multi-carousel-item.react-multi-carousel-item--active.carousel-item-padding-40-px {
  /* margin: 0px 0px 0px 20px */
  margin-left: 10px;
}
.react-multi-carousel-item.carousel-item-padding-40-px {
  /* margin: 0px 0px 0px 20px */
  width: 50%;
}
.react-multi-carousel-list.carousel-container {
  height: 65vh;
}
.react-multi-carousel-track {
  height: 100%;
}
.slide1 {
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}
.download-button {
  background: transparent linear-gradient(270deg, #4bb9ae 0%, #67c7be 100%) 0%
    0% no-repeat padding-box !important;
  width: 200px;
  height: 50px;
  border-radius: 100px;
  cursor: pointer;
}
.button-text {
  color: #ffffff;
  font-family: "GeneralSans-Medium";
  font-size: 18px;
  padding-top: 1px;

}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media (min-width: 320px) {
  .section1-container {
    padding:40px 0px 0px 0px;
    background-color: #ffffff;
    overflow: hidden;
    height: auto;
    margin-top: 0px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
  }
  .section1-part1 {
    padding: 0px 20px 0px 20px;
    display: block;
    width: 100%;
  }
  .section1-part1-div1 {
    width: 100%;
    max-height: 170px;
  }
  .section1-part1-div2 {
    width: 100%;
    max-height: 100px;
    display: flex;
    justify-content: left;
    margin-top: 30px;
  }
  .section1-part1-span1 {
    font-family: "GeneralSans-Bold";
    font-size: 40px;
    line-height: 50px;
  }
  .section1-part1-span2 {
    font-family: "Arial, Helvetica, sans-serif";
    font-size: 14px;
    color: #aaaaaa;
  }
  .mobile-carousel{
    width: 100px;
  }
}
/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
  .section1-container {
    padding: 0px 0px 0px 0px;
    background-color: #ffffff;
    overflow: hidden;
    height: 90vh;
    border-top-right-radius: 60px;
    border-top-left-radius: 60px;
    margin-top: 0px;
  }
  .section1-part1 {
    padding: 40px 80px 40px 80px;
    display: flex;
    width: 100%;
  }
  .section1-part1-div1 {
    width: 100%;
    max-height: 100px;
  }
  .section1-part1-div2 {
    max-height: 100px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .section1-part1-span1 {
    font-family: "GeneralSans-Bold";
    font-size: 55px;
    line-height: 60px;
  }
  .section1-part1-span2 {
    font-family: "Arial, Helvetica, sans-serif";
    font-size: 20px;
    color: #aaaaaa;
  }
}
/* hi-res laptops and desktops */
@media (min-width: 1281px) {
  .section1-container {
    border-top-right-radius: 60px;
    border-top-left-radius: 60px;
    margin-top: 0px;
    padding: 0px 0px 0px 0px;
    background-color: #ffffff;
    overflow: hidden;
    height: auto;
  }
  .section1-part1 {
    padding: 40px 80px 40px 80px;
    display: flex;
    width: 100%;
  }
  .section1-part1-div1 {
    width: 100%;
    max-height: 100px;
  }
  .section1-part1-div2 {
    max-height: 100px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    width: 200px;
  }
  .section1-part1-span1 {
    font-family: "GeneralSans-Bold";
    font-size: 55px;
    line-height: 60px;
  }
  .section1-part1-span2 {
    font-family: "Arial, Helvetica, sans-serif";
    font-size: 20px;
    color: #aaaaaa;
  }
}

/* hi-res laptops and desktops */
@media (min-width: 3840) {
  .section1-container {
    border-top-right-radius: 60px;
    border-top-left-radius: 60px;
    margin-top: 0px;
    padding: 0px 0px 0px 0px;
    background-color: red;
    overflow: hidden;
    height: auto;
  }
  .section1-part1 {
    padding: 40px 80px 40px 80px;
    display: flex;
    width: 100%;
  }
  .section1-part1-div1 {
    width: 100%;
    max-height: 100px;
  }
  .section1-part1-div2 {
    max-height: 100px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    width: 200px;
  }
  .section1-part1-span1 {
    font-family: "GeneralSans-Bold";
    font-size: 55px;
    line-height: 60px;
  }
  .section1-part1-span2 {
    font-family: "Arial, Helvetica, sans-serif";
    font-size: 20px;
    color: #aaaaaa;
  }
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  /* margin: 0 -10px; */
}
