.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  top: 0px;
left: 0px;
/* UI Properties */
background: transparent url('./assets/images/Rectangle\ 1879@2x.png') 0% 0% no-repeat padding-box;
opacity: 1;
display: flex;
justify-content: center;
}
:root {
  --inverted-corners-background-1: #bbf2ef;
  --inverted-corners-background-2: #4ebaac;
  --inverted-corners-size: 2rem;
}
.dynamicHeader{
  width: 100vw;
  height: 100px;
  display: flex;
  background-image:linear-gradient(to left, #4bb9ab, #6ac7bd, #86d5ce, #a1e4df, #bcf2ef);
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0
}

.dynamicHeader::before {
    content: '';
  
    /* Absolute position */
    bottom: calc(-2 * var(--inverted-corners-size));
    left: 0;
    position: absolute;
  
    /* Size */
    height: calc(2 * var(--inverted-corners-size));
    width: var(--inverted-corners-size);
  
    /* Border */
    background-color: transparent;
    border-top-left-radius: var(--inverted-corners-size);
    box-shadow: var(--inverted-corners-background-1) 0px calc(-1 * var(--inverted-corners-size)) 0px 0px;
  }
  
  .dynamicHeader::after {
    content: '';
  
    /* Absolute position */
    bottom: calc(-2 * var(--inverted-corners-size));
    right: 0;
    position: absolute;
  
    /* Size */
    height: calc(2 * var(--inverted-corners-size));
    width: var(--inverted-corners-size);
  
    /* Border */
    background-color: transparent;
    border-top-right-radius: var(--inverted-corners-size);
    box-shadow: var(--inverted-corners-background-2) 0px calc(-1 * var(--inverted-corners-size)) 0px 0px;
  }

button {
  width: 230px;
  background: transparent !important;
}
/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
@media (min-width:320px) {
button {
  width: 150px;
  background: transparent !important;
}
} 
/* big landscape tablets, laptops, and desktops */ 
@media (min-width:1025px) {
  button {
    width: 230px;
    background: transparent !important;
  }
 }
 /* hi-res laptops and desktops */
@media (min-width:1281px) {
  button {
    width: 230px;
    background: transparent !important;
  }
}
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
