.section5-container {
  position: relative;
  padding: 80px 40px 80px 40px;
  display: flex;
}
.section5-div-l {
  width: 50%;
}

.section5-div-r {
  width: 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section5-div-r1 {
  width: 80%;
}
.section5-span {
  font-size: 75px;
  font-family: "GeneralSans-Bold";
  color: #ffff;
  line-height: 80px;
}
.section5-div-r2 {
  display: flex;
  justify-content: center;
}
.section5-div-r2-d1 {
  width: 50%;
  margin: 20px 0px 20px 0px;
}
.section5-p {
  color: #d4efea;
  font-size: 18px;
  font-family: "GeneralSans-Medium";
  line-height: 24px;
}





/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media (min-width: 320px) {
  .section5-container {
    position: relative;
    padding: 30px 20px 40px 20px;
    display: flex;
    flex-direction: column;
  }
  .section5-div-l {
    width: 100%;
  }

  .section5-div-r {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .section5-div-r1 {
    width: 100%;
  }
  .section5-span {
    font-size: 40px;
    font-family: "GeneralSans-Bold";
    color: #ffff;
    line-height: 50px;
  }
  .section5-div-r2 {
    display: flex;
    justify-content: center;
  }
  .section5-div-r2-d1 {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
  .section5-p {
    color: #d4efea;
    font-size: 18px;
    font-family: "GeneralSans-Medium";
    line-height: 24px;
  }
}
/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
  .section5-container {
    position: relative;
    padding: 80px 40px 80px 40px;
    display: flex;
    flex-direction: row;
  }
  .section5-div-l {
    width: 50%;
  }

  .section5-div-r {
    width: 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .section5-div-r1 {
    width: 100%;
  }
  .section5-span {
    font-size: 75px;
    font-family: "GeneralSans-Bold";
    color: #ffff;
    line-height: 80px;
  }
  .section5-div-r2 {
    display: flex;
    justify-content: center;
  }
  .section5-div-r2-d1 {
    width: 50%;
    margin: 20px 0px 20px 0px;
  }
  .section5-p {
    color: #d4efea;
    font-size: 18px;
    font-family: "GeneralSans-Medium";
    line-height: 24px;
  }
}
/* hi-res laptops and desktops */
@media (min-width: 1281px) {
  .section5-container {
    position: relative;
    padding: 80px 40px 80px 40px;
    display: flex;
    flex-direction: row;
  }
  .section5-div-l {
    width: 50%;
  }

  .section5-div-r {
    width: 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .section5-div-r1 {
    width: 100%;
  }
  .section5-span {
    font-size: 75px;
    font-family: "GeneralSans-Bold";
    color: #ffff;
    line-height: 80px;
  }
  .section5-div-r2 {
    display: flex;
    justify-content: center;
  }
  .section5-div-r2-d1 {
    width: 50%;
    margin: 20px 0px 20px 0px;
  }
  .section5-p {
    color: #d4efea;
    font-size: 18px;
    font-family: "GeneralSans-Medium";
    line-height: 24px;
  }
}
