.section4-container {
 padding: 80px 100px 80px 100px;
background-color: #ffff;
border-radius: 40px;
 height:90vh;
 display: flex;
}  
.section4-span{
  font-family: 'GeneralSans-Bold';
  font-size: 80px;

}
.section4-div-l{
  display: flex;
  width: 50%;
}
.section4-div-r{
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.section4-div1-p{
  font-size: 18px;
  font-family: 'GeneralSans-Medium';
  line-height: 24px;
  color:#AAAAAA
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
@media (min-width:320px) {
  .section4-container {
    padding: 30px 20px 40px 20px;
    background-color: #ffff;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    height:auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
   }  
   .section4-span{
     font-family: 'GeneralSans-Bold';
     font-size: 40px;
    line-height: 50px;
   }
   .section4-div-l{
     display: flex;
     width: 100%;
   }
   .section4-div-r{
     width: 100%;
     display: flex;
     justify-content: center;
     flex-direction: column;
     margin-bottom: 0px;
   }
   .section4-div1-p{
     font-size: 18px;
     font-family: 'GeneralSans-Medium';
     line-height: 24px;
     color:#AAAAAA
   }
} 
/* big landscape tablets, laptops, and desktops */ 
@media (min-width:1025px) {
  .section4-container {
    padding: 80px 100px 80px 100px;
   background-color: #ffff;
   border-bottom-left-radius: 40px;
   border-bottom-right-radius: 40px;
    height:auto;
    display: flex;
    flex-direction: row;
    overflow: hidden;
   }  
   .section4-span{
     font-family: 'GeneralSans-Bold';
     font-size: 80px;
     line-height: 90px;
   }
   .section4-div-l{
     display: flex;
     width: 50%;
   }
   .section4-div-r{
     width: 50%;
     display: flex;
     justify-content: flex-end;
     flex-direction: column;
     margin-bottom: 100px;
   }
   .section4-div1-p{
     font-size: 18px;
     font-family: 'GeneralSans-Medium';
     line-height: 24px;
     color:#AAAAAA
   }
}
/* hi-res laptops and desktops */
@media (min-width:1281px) {
  .section4-container {
    padding: 80px 100px 80px 100px;
   background-color: #ffff;
   border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    height:auto;
    display: flex;
    flex-direction: row;
    overflow: hidden;
   }  
   .section4-span{
     font-family: 'GeneralSans-Bold';
     font-size: 80px;
     line-height: 90px;
   }
   .section4-div-l{
     display: flex;
     width: 50%;
   }
   .section4-div-r{
     width: 50%;
     display: flex;
     justify-content: flex-end;
     flex-direction: column;
     margin-bottom: 100px;
   }
   .section4-div1-p{
     font-size: 18px;
     font-family: 'GeneralSans-Medium';
     line-height: 24px;
     color:#AAAAAA
   } 
}
