.carousel-container{
    height:70vh;
    display: flex;
    margin: 0px 10px 0px 10px;
    width: 100%;
    background: url('../../assets/images/websitebanner2levels.png') no-repeat;
}
.carousel-container-mobile{
    /* height: 60vh; */
    display: flex;
    margin: 0px 20px 0px 0px;
    width: 100%;
    /* background: url('https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/mobilelandingimagefinal.png') no-repeat; */
}
.image1{
    width: 74%;
    height: 52vh;
    object-fit: cover;
}
.image2{
    margin-right: 10px;
    /* width: 50%; */
    object-fit: fill;
}
.image3{
    /* width: 50%; */
    margin-right: 10px;
    object-fit: fill;
}
.image4{
    /* width: 50%; */
    object-fit: fill;
}
.taglineImage {
 width: 1500px;
}
/* .item {
    background: url('https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/carousel.png');
} */

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
@media (min-width:320px) {
    .carousel-container-mobile{
        display: flex;
        margin: 20px 0px 0px 0px;
        width: 100%;
        background-size: 100% 100%;;
    }
    /* .item{
        background-size: auto 100px;
    } */
} 
/* big landscape tablets, laptops, and desktops */ 
@media (min-width:1025px) {
    .carousel-container{
        height: 62vh;
        display: flex;
        margin: 0px 10px 0px 10px;
        width: 100%;
        background-size: 100% 100%;
    }
    /* .item{
        background-size:100% 100%;
       
    } */

}
/* hi-res laptops and desktops */
@media (min-width:1281px) {
    .carousel-container{
        height: 62vh;
        display: flex;
        margin: 0px 10px 0px 10px;
        width: 100%;
        background-size: 100% 100%;
    }
    /* .item{
        background-size:100% 100%;
        
    } */
}