.section6-container {
  padding: 80px 100px 80px 100px;
  background-color: #ffff;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  display: flex;
}
.section6-span {
  color: #4db6ac;
  font-size: 75px;
  font-family: "GeneralSans-Bold";
}
.section6-div1 {
  padding-top: 40px;
  display: flex;
  padding-bottom: 0px;
}
.section6-downloads1 {
  width: 300px;
  cursor: pointer;
}
.section6-downloads2 {
  width: 300px;
  cursor: pointer;
}
/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media (min-width: 320px) {
  .section6-container {
    padding: 30px 20px 30px 20px;
    background-color: #ffff;
    border-radius: 40px;
    display: flex;
  }
  .section6-span {
    color: #4db6ac;
    font-size: 40px;
    font-family: "GeneralSans-Bold";
    line-height: 50px;
  }
  .section6-div1 {
    padding-top: 20px;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right:0px;
  }
  .section6-downloads1 {
    width: 150px;
    cursor: pointer;
  }
  .section6-downloads2 {
    width: 150px;
    cursor: pointer;
    margin-left: 10px;
  }
}
/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
  .section6-container {
    padding: 80px 100px 80px 100px;
    background-color: #ffff;
    border-radius: 40px;
    display: flex;
  }

  .section6-span {
    color: #4db6ac;
    font-size: 75px;
    font-family: "GeneralSans-Bold";
    line-height: 80px;
  }
  .section6-div1 {
    padding-top: 40px;
    display: flex;
    justify-content: left;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .section6-downloads1 {
    width: 225px;
    cursor: pointer;
  }
  .section6-downloads2 {
    width: 225px;
    cursor: pointer;
    margin-left: 25px;
  }
}
/* hi-res laptops and desktops */
@media (min-width: 1281px) {
  .section6-container {
    padding: 80px 100px 80px 100px;
    background-color: #ffff;
    border-radius: 40px;
    display: flex;
  }
  .section6-span {
    color: #4db6ac;
    font-size: 75px;
    font-family: "GeneralSans-Bold";
    line-height: 80px;
  }
  .section6-div1 {
    padding-top: 40px;
    display: flex;
    justify-content: left;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .section6-downloads1 {
    width: 225px;
    cursor: pointer;

  }
  .section6-downloads2 {
    width: 225px;
    cursor: pointer;
    margin-left: 25px;
  }
}
